import React, { Component } from "react";
import axios from "axios";

class ErrorComponent extends Component {
  constructor() {
    super();
    this.state = {
      Stats: []
    };
  }


  componentDidMount() {
    

}


  render() {

    return (
      <div>

        
        <div className="container">
          <div id="notfound">
            <div className="notfound">
              <h2>Page not found</h2>
              <p>The page you are looking for might have been removed or is temporarily unavailable.</p>
              <a href="/">home page</a>
            </div>
          </div>
          <div className="container">
            <img className="Errorimg" src="/img/Wallet404.png" alt />       {/* Image for error page*/}
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorComponent;
