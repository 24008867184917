import React, { Component } from "react";
import axios from "axios";
import LoadingBar from 'react-top-loading-bar';


class GameEndComponent extends Component {
  constructor() {
    super();
    this.state = {
      OfficeCode: "",
      GameID: "",
      IsBA:"",
      GameState: {},
      GameHistory: [],
      Players: [],
      PlayerNumber: 0,
      PlayerName: "",
      WealthiestPlayerText: " ",
      Error: null,
      Icons: ["fas fa-2x fa-user-tie", "fas fa-2x fa-dollar-sign", "fas fa-2x fa-piggy-bank", "fas fa-2x fa-money-bill-alt"],
      IconColors: ["blue", "red", "green", "black"],
      CardScenario: {
        PlayerName: "Player",
        Player: "1",
        COU: 0,
        MITB: 0,
        WOS: 0,
      }
    };
    this.MyLoadingBar = React.createRef();
  }

  componentDidMount() {

    // this.MyLoadingBar.current.continuousStart();

    //Get parameters
    this.setState({
      GameID: this.props.match.params.GameID,
      IsBA: this.props.match.params.BA,
    }, () => {

      //Get GameState
      const GameID = this.state.GameID;
      let PromGetGameState = axios.post("/api/GetGameState", { GameID }).then((Result) => {

        if (Result.data.Success) {
          this.setState({ GameState: Result.data.GameData }, () => {
            if (this.state.GameState.GameStatus !== 2) {
              this.props.history.push("/404");
            };
          });
        } else {
          alert(Result.data.Reason);
        };
      }).catch((Error) => {
        alert("error2" + Error)
      });

      //Get all players
      let PromPlayers = axios.post("/api/GetGamePlayers", { GameID }).then((Result) => {
        if (Result.data.Success) {
          this.setState({ Players: Result.data.Players });
        } else {
          this.setState({
            Error:
              <div className="alert alert-danger">{Result.data.Reason}</div>
          });
          // this.MyLoadingBar.current.complete();
        };
      });

      Promise.all([PromGetGameState, PromPlayers]).then(() => {
        // this.MyLoadingBar.current.complete();

        //Ref parent (this)
        let Parent = this;

        //Create our game loop to loop and get data every 4 seconds
        setInterval(function () {

          // Parent.MyLoadingBar.current.continuousStart();

          const GameID = Parent.state.GameID;

          //Update game state
          let PromGameState = axios.post("/api/GetGameState", { GameID }).then((Result) => {
            if (Result.data.Success) {
              Parent.setState({ GameState: Result.data.GameData });
            };
          });

          //Update players
          let PromPlayers = axios.post("/api/GetGamePlayers", { GameID }).then((Result) => {
            if (Result.data.Success) {
              Parent.setState({ Players: Result.data.Players });
            };
          });

          //Update game history here
          const OfficeCode = Parent.state.GameState.OfficeCode;
          let PromGameHistory = axios.post("/api/GetGameHistory", { OfficeCode }).then((Result) => {
            if (Result.data === null) {
              //display error message
              Parent.setState({
                ErrorMessage: <div className="alert alert-warning"> Game History Could Not Be Found </div>
              })
            } else {
              Parent.setState({ GameHistory: Result.data })
            }
          });

          Promise.all([PromGameState, PromPlayers, PromGameHistory]).then(() => {

            //check if game is deleted
            if (Parent.state.GameState.GameStatus !== 2) {
              Parent.props.history.push("/");
            }

            // Parent.MyLoadingBar.current.complete();
          });

        }, 10000);//End game loop

      });//End promise all

    });//End

  };//end component did mount

  UpdateScenarioModal(Player) {
    this.setState({
      CardScenario: {
        PlayerName: Player.PlayerName,
        Player: Player.PlayerNumber,
        COU: Player.CustomersOweUs,
        MITB: Player.MoneyInTheBank,
        WOS: Player.WeOweSuppliers
      }
    });
  };

  //this function deletes the lobby
  DeleteGame() {
    var OfficeCode = this.state.GameState.OfficeCode
    this.props.history.push("/");
    axios.post("/api/DeleteOfficeAndPlayers", { OfficeCode })
      .then((res) => {
        this.setState({ });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {

    //Render game history here
    const GameHistoryData = this.state.GameHistory.map((HistoryObj, iloop) => (
      <table className="tableGH table" border="1">
        <tr>
          <td>{HistoryObj.PlayerName + " - " + HistoryObj.Event}</td>
        </tr>
      </table>
    ));

    //load the Players
    const PlayersData = this.state.Players.map((Player, iLoop) => (
      <tr>
        <th>{Player.PlayerNumber}</th>
        <td>{Player.PlayerName}</td>
        <td><i style={{ color: this.state.IconColors[iLoop] }} className={this.state.Icons[iLoop]}></i></td>
        <td>{Player.IsAlive ? "Alive" : "Dead"}</td>
        <td>{Player.NetWorth}</td>
        <td>{<button data-toggle="modal" onClick={() => this.UpdateScenarioModal(Player)} data-target="#exampleModal" className="btn-sm btn-primary LegderButton" type="button">Money Record</button>}</td>
      </tr>
    ));

    return (
      <div>

        {/* Modal for Deleting Game  */}
        <div>
          <div class="modal fade" id="modalDeleteGame" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollable" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalScrollable">Delete Big Bucks Office</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p>Are you sure you want to delete this game office?</p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-danger" onClick={() => this.DeleteGame()} data-dismiss="modal">Delete</button>
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal for player scenario */}
        <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{"Viewing Scenario For: " + this.state.CardScenario.PlayerName}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item"><strong>Customers Owe Us: </strong>{"BB -> " + this.state.CardScenario.COU}</li>
                  <li className="list-group-item"><strong>Money In The Bank: </strong>{"BB -> " + this.state.CardScenario.MITB}</li>
                  <li className="list-group-item"><strong>We Owe Suppliers: </strong>{"BB -> " + this.state.CardScenario.WOS}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Table Section*/}
        <section className="bg-light">
          <div className="container py-5">
            <div className="row">
              <div className="col">
                <p className="h6 center text-uppercase text-primary">Board Game History </p>
                <h2 className="center text-uppercase">Thanks for playing the Big Bucks board game!</h2>
                <h3 className="center text-uppercase text-small text-muted">View the entire game history below:</h3>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="container">
                  <div className="BoardLogo">
                    <div className="BoardLogoAnimation:hover">
                      <img className="img-fluid" src="/img/BigBucksLogo.png" alt="BigBucksLogo" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {/* Game history table */}
              <h4>Game History</h4>
              <table className="table tableGH tableGH-scrollbar tableGH-wrapper-scroll-y">
                <tbody>
                  {GameHistoryData}
                </tbody>
              </table>
            </div>
          </div>
        </section>

        {/* Game Highlights Section*/}
        <section>
          <div className="container pt-5">
            <div className="row">
              <div className="col">
                <div className="GameCard">
                  <h3>Players</h3>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Token</th>
                        <th scope="col">Status</th>
                        <th scope="col">Net worth</th>
                        <th scope="col">View Ledger</th>
                      </tr>
                    </thead>
                    <tbody>
                      {PlayersData}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* Delete Game Lobby */}
            <div className="container pt-5">
              <div className="row">
                <div className="col">
                  <p>{this.state.IsBA === "159" ? (
                    <div>
                      <button data-toggle="modal" data-target="#modalDeleteGame" className="btn btn-sm btn-primary btn-block DeleteGameBtn" type="button">Delete Game Lobby</button>
                    </div>
                  ) : (
                    <div></div>
                  )}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
    );
  }
}

export default GameEndComponent;
