import React, { Component } from "react";
import axios from "axios";
import LoadingBar from 'react-top-loading-bar';

class JoinOfficeComponent extends Component {
  constructor() {
    super();
    this.state = {
      frmOfficeCode: "",
      frmPlayerName: "",
      ResponseMessage: null,
      EnterButtonDisabled: false,
      EnterButtonText: "Enter Office"
    };
    this.MyLoadingBar = React.createRef();
  };

  componentDidMount() {
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };


  onSubmit = (e) => {
    e.preventDefault();
    this.MyLoadingBar.current.continuousStart();
    this.setState({EnterButtonDisabled: true})

    const {frmOfficeCode, frmPlayerName} = this.state;
    axios.post("/api/AddPlayerToOffice", {frmOfficeCode, frmPlayerName}).then((Result) => {
      if(Result.data.Success){        
        this.MyLoadingBar.current.complete();
        this.props.history.push("/InOffice/" + Result.data.ID + "/" + this.state.frmPlayerName);
      }else{
        this.setState({ResponseMessage:
          <div className="alert alert-danger">{Result.data.Reason}</div>,
          EnterButtonText: "Refresh Page And Try Again"
        }, () => this.MyLoadingBar.current.complete());
      };
    });
  };



  render() {
    return (
      <div>
        <LoadingBar color="orange" height={10} ref={this.MyLoadingBar} shadow={true} />
        <form onSubmit={this.onSubmit}>
          <div className="login-form">
            <h2 className="text-center">Enter Big Bucks Office</h2>
            <div className="form-group text-center">
              <label for="frmOfficeCode">Enter Your Office Code/Key</label>
              <input placeholder="Enter Your 5-Letter Office Code/Key Here" required onChange={this.onChange} maxlength="5" minLength="5" id="frmOfficeCode" type="text" className="form-control text-center"></input>
            </div>
            <div className="form-group text-center">
              <label for="frmPlayerName">Enter Your Name Here</label>
              <input placeholder="Enter Your Name Here" required onChange={this.onChange} id="frmPlayerName" type="text" className="form-control text-center"></input>
            </div>
            <div className="form-group text-center">
              <label>By Playing The BigBucks Online Game, You Agree To Our <a target="_blank" href="/terms-and-conditions">Terms & Conditions</a></label>
            </div>
            <div className="form-group">
              <button disabled={this.state.EnterButtonDisabled} type="submit" className="btnCreateLobby btn-primary btn-block btn-lg">{this.state.EnterButtonText}</button>
            </div>
            {this.state.ResponseMessage}
          </div>
        </form>
      </div>
    );
  }
}

export default JoinOfficeComponent;