import React, { Component } from "react";
import axios from "axios";
import LoadingBar from 'react-top-loading-bar';

class InOfficeComponent extends Component {
  constructor() {
    super();
    this.state = {
      GameID: "",
      PlayerName: "",
      Error: null,
      GameState: {},
      Players: [],
      Icons: ["fas fa-2x fa-user-tie", "fas fa-2x fa-dollar-sign", "fas fa-2x fa-piggy-bank", "fas fa-2x fa-money-bill-alt"],
      IconColors: ["blue", "red", "green", "black"],
      CardScenario: {
        PlayerName: "Player",
        Player: "1",
        COU: 0,
        MITB: 0,
        WOS: 0,
      },
    };
    this.MyLoadingBar = React.createRef();
  };

  componentDidMount() {

    this.MyLoadingBar.current.continuousStart();

    //Get parameters
    this.setState({
      GameID: this.props.match.params.GameID,
      PlayerName: this.props.match.params.PlayerName.toUpperCase()
    }, () => {

      const GameID = this.state.GameID;

      //Get the game state
      let PromGameState = axios.post("/api/GetGameState", { GameID }).then((Result) => {
        if (Result.data.Success) {
          this.setState({ GameState: Result.data.GameData });
        } else {
          this.setState({
            Error:
              <div className="alert alert-danger">{Result.data.Reason}</div>
          });
          this.MyLoadingBar.current.complete();
        };
      });

      //Get Players
      let PromPlayers = axios.post("/api/GetGamePlayers", { GameID }).then((Result) => {
        if (Result.data.Success) {
          this.setState({ Players: Result.data.Players });
        } else {
          this.setState({
            Error:
              <div className="alert alert-danger">{Result.data.Reason}</div>
          });
          this.MyLoadingBar.current.complete();
        };
      });

      Promise.all([PromGameState, PromPlayers]).then(() => {
        //After receive all data successfully
        this.MyLoadingBar.current.complete();

        let Parent = this;

        //Set our intervals to automatically get the game state and players
        setInterval(function(){
          Parent.MyLoadingBar.current.continuousStart();

          const GameID = Parent.state.GameID;

          //Update game state
          let PromGameState = axios.post("/api/GetGameState", { GameID }).then((Result) => {
            if (Result.data.Success) {
              Parent.setState({ GameState: Result.data.GameData });
            };
          });

          //Update players
          let PromPlayers = axios.post("/api/GetGamePlayers", { GameID }).then((Result) => {
            if (Result.data.Success) {
              Parent.setState({ Players: Result.data.Players });
            };
          });

          if(Parent.state.GameState.GameStatus >= 1){
            Parent.props.history.push("/PlayGame/" + Parent.state.GameID + "/" + Parent.state.PlayerName);
          };

          Promise.all([PromGameState, PromPlayers]).then(() => {
            Parent.MyLoadingBar.current.complete();
          });

        }, 4000);

      });


    });

  };

  StartGame() {
  };

  SendPlayerInfo() {
  };

  UpdateScenarioModal(Player){
    this.setState({
      CardScenario: {
        PlayerName: Player.PlayerName,
        Player: Player.PlayerNumber,
        COU: Player.CustomersOweUs,
        MITB: Player.MoneyInTheBank,
        WOS: Player.WeOweSuppliers
      }
    });
  };

  render() {

    const PlayersTableData = this.state.Players.map((Player, iLoop) => (
      <tr className={Player.PlayerName === this.state.PlayerName ? "bg-primary" : ""} key={Player._id}>
        <td>{Player.PlayerNumber}</td>
        <td>{Player.PlayerName}</td>
        <td>
          <div className="Icons1" >
            <i style={{ color: this.state.IconColors[iLoop] }} className={this.state.Icons[iLoop]}></i>
          </div>
        </td>
        <td><button onClick={() => this.UpdateScenarioModal(Player)} data-toggle="modal" data-target="#exampleModal" className="btn btn-outline-dark">View</button></td>
      </tr>
    ));

    const InfoCard =
      <div className="card">
        <div className="card-body">
          <h3 className="card-title">Big Bucks Office</h3>
          <p className="card-text">Game Will Automatically Start When The Business Advisor Is Ready. Here Are The Office Settings And Rules.</p>
        </div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item"><strong>Business Advisor: </strong>{this.state.GameState.BusinessAdvisorName}</li>
          <li className="list-group-item"><strong>Current Office Code/Key: </strong>{this.state.GameState.OfficeCode}</li>
          <li className="list-group-item"><strong>Player Turn Time: </strong>{this.state.GameState.GameTurnTime + " Minutes"}</li>
        </ul>
        <div className="card-body">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text"><a target="_blank" href={this.state.GameState.LedgerLink}>Open Money Record</a></span>
            </div>            
            <input onClick={() => {navigator.clipboard.writeText(this.state.GameState.LedgerLink)}} value={this.state.GameState.LedgerLink} readOnly={true} type="text" className="form-control" />
          </div>
        </div>
      </div>

    return (
      <div>
        <LoadingBar color="orange" height={10} ref={this.MyLoadingBar} shadow={true} />
        <br></br>
        <div className="container">
          {this.state.Error}
          {InfoCard}
          <br></br>
          <table className="table table-hover table-center text-center">
            <thead>
              <tr>
                <th>#</th>
                <th>Business Partner</th>
                <th>Game Avatar</th>
                <th>View Scenario</th>
              </tr>
            </thead>
            <tbody>
              {PlayersTableData}
            </tbody>
          </table>
          <div className="Container">
            <div className="BoardRotate">
              <input id="nrml" type="radio" name="rotate" />
              <input id="rt90" type="radio" name="rotate" />
              <input id="rt180" type="radio" name="rotate" />
              <input id="rt270" type="radio" name="rotate" />
              <div className="btn-board">
                <label className="rt-btn" for="rt90"></label>
                <label className="rt-btn" for="rt180"></label>
                <label className="rt-btn" for="rt270"></label>
                <label className="rt-btn" for="nrml"></label>
              </div>
              <img className="img-fluid target" src="/img/BigBucksBoard.png" alt />
            </div>
          </div>
        </div>
        <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{"Viewing Scenario For: " + this.state.CardScenario.PlayerName}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item"><strong>Customers Owe Us: </strong>{"BB -> " + this.state.CardScenario.COU}</li>
                  <li className="list-group-item"><strong>Money In The Bank: </strong>{"BB -> " + this.state.CardScenario.MITB}</li>
                  <li className="list-group-item"><strong>We Owe Suppliers: </strong>{"BB -> " + this.state.CardScenario.WOS}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InOfficeComponent;