import React from "react";
import styled from "styled-components";
import { Marginer } from "../marginer";

const DetailsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 2.5em 6px 0 6px;
  line-height: 1.4;
`;

const MediumText = styled.span`
  font-size: 14px;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const ViewButton = styled.button`
  padding: 10px 16px;
  background-color: #e69412;
  color: #000;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  border: 3px solid transparent;
  outline: none;
  cursor: pointer;
  transition: all 290ms ease-in-out;
  border-radius: 8px;

  &:hover {
    background-color: transparent;
    color: #fff;
    border: 3px solid #fbbe01;
  }
`;

export function CardDetails(props) {
  return (
    <DetailsContainer>
      <MediumText className="center">Surprise Card</MediumText>
      <Marginer direction="vertical" margin="1.2em" />
      <ViewButton>Click to view</ViewButton>
    </DetailsContainer>
  );
}
