import React, { Component } from "react";
import axios from "axios";
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

class JoinObserverComponent extends Component {
  constructor() {
    super();
    this.state = {
      Stats: [],
      Notification: <div></div>,

    };
  }


  componentDidMount() {
    
    

}

onChange = (e) => {
  this.setState({ [e.target.id]: e.target.value });
  console.log(this.state);
};

onSubmit = (e) => {
  e.preventDefault();


 
  const OfficeCode = this.state.OfficeCode;
  
  
  axios.post("/api/DoesLobbyExist",{OfficeCode}).then((Result)=>{
    if(Result.data.Success){
      
      this.props.history.push("/Observe/" + Result.data.Game._id);
    }else{
      this.setState({Notification: <div className="alert alert-danger center">{Result.data.Reason}</div>})
    }
  })
  
}


  render() {

    return (
      <div>
        <div>{this.state.Notification}</div>
        <form onSubmit={this.onSubmit}>
        <div className="login-form">
          <h2 className="text-center">Observe A Game</h2>
          
          <div className="form-group text-center">
            <label for="frmPlayerName">Enter Office Code</label>
            <input placeholder="Enter Your 5-Letter Office Code/Key Here" required onChange={this.onChange} maxlength="5" minLength="5" id="OfficeCode" type="text" className="form-control text-center"></input>
          </div>

          <div className="form-group">
            <button type="submit" className="btnCreateLobby btn-primary btn-block btn-lg">Observe Game</button>
          </div>
          </div>
        </form>
        
      </div>
    );
  }
}

export default JoinObserverComponent;
