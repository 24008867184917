import React, { Component } from "react";
import axios from "axios";
import { Route } from "react-router";
import LoadingBar from 'react-top-loading-bar';

class ObserverComponent extends Component {
  constructor() {
    super();
    this.state = {
      GameID: "",
      PlayerName: "",
      Error: null,
      GameState: {},
      BoardData: [],
      NetWorth: 0,
      Players: [],
      Icons: ["fas fa-2x fa-user-tie", "fas fa-2x fa-dollar-sign", "fas fa-2x fa-piggy-bank", "fas fa-2x fa-money-bill-alt"],
      IconColors: ["blue", "red", "green", "black"],
      CardScenario: {
        PlayerName: "Player",
        Player: "1",
        COU: 0,
        MITB: 0,
        WOS: 0,
      },
      GameHistory: [],
      GameState: null,
      ErrorMessage: null,
      ModalTitle: "Please Roll The Die First",
      ModalContent: "",
      ModalContentHidden: ""
    };
    this.MyLoadingBar = React.createRef();
    this.MyScenarioImage = React.createRef();
  }


  componentDidMount() {
    //Get parameters
    this.setState({
      GameID: this.props.match.params.GameID,

    }, () => {

      //Get the board data
      let PromGetBoardData = axios.get("/api/GetBoard").then((Result) => {
        this.setState({ BoardData: Result.data });
      }).catch((Error) => {
        alert("error1");
      });

      //Get GameState
      const GameID = this.state.GameID;
      let PromGetGameState = axios.post("/api/GetGameState", { GameID }).then((Result) => {

        if (Result.data.Success) {
          this.setState({ GameState: Result.data.GameData }, () => {
            if (this.state.GameState.GameStatus === 2) {
              //game end page
              this.props.history.push("/EndGame/" + GameID + "/100");
            };
          });
        } else {
          alert(Result.data.Reason);
        };

      }).catch((Error) => {
        alert("error2" + Error)
      });

      //Get Player

      const frmGameID = this.state.GameID;


      //Get all players also
      let PromPlayers = axios.post("/api/GetGamePlayers", { GameID }).then((Result) => {
        if (Result.data.Success) {
          this.setState({ Players: Result.data.Players });
        } else {
          this.setState({
            Error:
              <div className="alert alert-danger">{Result.data.Reason}</div>
          });
          this.MyLoadingBar.current.complete();
        };
      });

      Promise.all([PromGetBoardData, PromGetGameState, PromPlayers]).then(() => {
        this.MyLoadingBar.current.complete();

        //Ref parent (this)
        let Parent = this;

        //Create our game loop to loop and get data every 4 seconds
        setInterval(function () {

          Parent.MyLoadingBar.current.continuousStart();

          const GameID = Parent.state.GameID;

          //Update game state
          let PromGameState = axios.post("/api/GetGameState", { GameID }).then((Result) => {
            if (Result.data.Success) {
              Parent.setState({ GameState: Result.data.GameData });
            };
          });

          //Update players
          let PromPlayers = axios.post("/api/GetGamePlayers", { GameID }).then((Result) => {
            if (Result.data.Success) {
              Parent.setState({ Players: Result.data.Players });
            };
          });

          //update my player as well
          //Get Player
          const frmPlayerName = Parent.state.PlayerName;
          const frmGameID = Parent.state.GameID;


          let PromEndGameCheck = axios.post("/api/EndGameCheck", { frmGameID }).then((Result) => { });
          //get game history
          const OfficeCode = Parent.state.GameState.OfficeCode;
          let PromGameHistory = axios.post("/api/GetGameHistory", { OfficeCode }).then((Result) => {
            if (Result.data === null) {
              //display error message
              Parent.setState({
                ErrorMessage: <div className="alert alert-warning"> Game History Could Not Be Found </div>
              })
            } else {
              Parent.setState({ GameHistory: Result.data })
            }
          });


          Promise.all([PromGameState, PromPlayers, PromEndGameCheck, PromGameHistory]).then(() => {

            //check
            if (Parent.state.GameState.GameStatus === 2) {
              Parent.props.history.push("/EndGame/" + Parent.state.GameState._id + "/100");
            }

            Parent.MyLoadingBar.current.complete();
          });

        }, 4000);//End game loop

      });//End promise all

    });//End

  }

  UpdateScenarioModal(Player) {
    this.setState({
      CardScenario: {
        PlayerName: Player.PlayerName,
        Player: Player.PlayerNumber,
        COU: Player.CustomersOweUs,
        MITB: Player.MoneyInTheBank,
        WOS: Player.WeOweSuppliers
      }
    });
  };

  render() {
    //Render game history here
    const GameHistoryData = this.state.GameHistory.map((HistoryObj, iloop) => (
      <table className="tableGH table" border="1">
        <tr>
          <td>{HistoryObj.PlayerName + " - " + HistoryObj.Event}</td>
        </tr>
      </table>
    ));

    const BoardDataObject = this.state.BoardData.map((Obj, iLoop) => (
      <div key={Obj._id} className="GridInfo center">
        <img className="boardimg" src={"/img/BigBucks/" + (iLoop + 1) + ".PNG"}></img>
        <div>{this.state.Players.map((Player, iLoop2) => (
          (iLoop + 1) === Player.PlayerPosition ? <td><i style={{ color: this.state.IconColors[iLoop2] }} className={this.state.Icons[iLoop2]}></i></td> : ""
        ))}</div>
      </div>
    ));

    const PlayersData = this.state.Players.map((Player, iLoop) => (
      <tr>
        <th>{Player.PlayerNumber}</th>
        <td>{Player.PlayerName}</td>
        <td><i style={{ color: this.state.IconColors[iLoop] }} className={this.state.Icons[iLoop]}></i></td>
        <td>{Player.IsAlive ? "Alive" : "Dead"}{(iLoop + 1) === this.state.GameState.CurrentPlayer ? " - Playing" : ""}</td>
        <td>{Player.NetWorth}</td>
        <td>{<button data-toggle="modal" onClick={() => this.UpdateScenarioModal(Player)} data-target="#exampleModal" className="btn-sm btn-primary LegderButton" type="button">Money Record</button>}</td>
      </tr>
    ));

    return (
      <div>

        <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{"Viewing Scenario For: " + this.state.CardScenario.PlayerName}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item"><strong>Customers Owe Us: </strong>{"BB -> " + this.state.CardScenario.COU}</li>
                  <li className="list-group-item"><strong>Money In The Bank: </strong>{"BB -> " + this.state.CardScenario.MITB}</li>
                  <li className="list-group-item"><strong>We Owe Suppliers: </strong>{"BB -> " + this.state.CardScenario.WOS}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <LoadingBar color="orange" height={10} ref={this.MyLoadingBar} shadow={true} />
        <br></br>

        <div className="center GameContainer GameSpacing">
          <div className="row">
            <div className="col-10 col-sm-10 col-lg-6 col-md-10">
              <div className="GameCard">
                <h3>Players</h3>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Token</th>
                      <th scope="col">Status</th>
                      <th scope="col">Net worth</th>
                      <th scope="col">View Record</th>
                    </tr>
                  </thead>
                  <tbody>
                    {PlayersData}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-10 col-sm-10 col-lg-6 col-md-10">
              <div className="GameCard">
                {/* Game history table */}
                <h3>Game History</h3>
                <table className="table tableGH tableGH-scrollbar tableGH-wrapper-scroll-y">
                  <tbody>
                    {GameHistoryData}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="GridContainer padding-top">
          {BoardDataObject}
        </div>

      </div>
    );
  }
}

export default ObserverComponent;
