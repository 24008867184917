import { BrowserRouter, Route } from "react-router-dom";
import HomePageComponent from "./components/home-component";
import CreateOfficeComponent from "./components/create-office-component";
import InOfficeComponent from "./components/in-office-component";
import JoinOfficeComponent from "./components/join-office-component";
import HowToPlayComponent from "./components/how-to-play-component";
import GameComponent from "./components/game-component";
import ErrorComponent from "./components/error-component";
import GameEndComponent from "./components/game-end-component";
import ObserverComponent from "./components/observer-component";
import JoinObserverComponent from "./components/join-observer-component";
import BusinessAdvisorControlPanelComponent from "./components/business-advisor-control-panel";
import TsAndCsComponent from "./components/ts-and-cs-component";
import { Link, Switch } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <BrowserRouter forceRefresh={true}>
        <div>
          {/* Website Paths */}
          <Switch>
          <Route exact path="/" component={HomePageComponent} />
          <Route exact path="/CreateOffice" component={CreateOfficeComponent} /> 
          <Route exact path="/InOffice/:GameID/:PlayerName" component={InOfficeComponent} />        
          <Route exact path="/JoinOffice" component ={JoinOfficeComponent}/>
          <Route exact path="/PlayGame/:GameID/:PlayerName" component ={GameComponent}/>
          <Route exact path="/BAControlPanel/:GameID/:BAName" component = {BusinessAdvisorControlPanelComponent}/>
          <Route exact path="/HowToPlay" component = {HowToPlayComponent}/>          
          <Route exact path="/EndGame/:GameID/:BA" component ={GameEndComponent}/>
          <Route exact path="/Observe/:GameID" component ={ObserverComponent}/>
          <Route exact path="/JoinObserver" component ={JoinObserverComponent}/>
          <Route exact path="/terms-and-conditions" component ={TsAndCsComponent}/>
          <Route component={ErrorComponent}/>
          </Switch>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;