import { motion, useMotionValue, useTransform } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { CardDetails } from "./CardDetails";
import { Dice5 } from '@styled-icons/bootstrap'

const CardWrapper = styled.div`
  width: 100%;
  perspective: 2000;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1366px) and (max-height: 768px) {
  
    max-width: 7em;
}
`;

// controlls the card sizing and style
const CardContainer = styled(motion.div)`
  width: 215px;
  height: 350px;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  box-shadow: 0 2px 7px 1px rgba(31, 31, 31, 0.2);
  background-color: #102d4a;
  color: #fff;
  position: relative;
  cursor: grab;

  @media (max-width: 1366px) and (max-height: 768px) {
  
    max-width: 8em;
    max-height: 16em;
}
`;

// controls the entire card
const TopContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1.2;
  position: relative;
  align-items: center;
  justify-content: flex-end;
`;

// controlls the view botton and bottom text
const BottomContainer = styled.div`  
  display: flex;
  flex: 0.8;
  padding: 0 1em;
`;

const CardText = styled.div`
  color: #67c148;
  text-transform: uppercase;
  z-index: 10;
  font-size: 250%;
  font-weight: 500;

  @media (max-width: 1366px) and (max-height: 768px) {
  
    max-width: 7em;
    font-size: 100%;
    font-weight: 250;
}
`;

const CardImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardImage = styled(motion.div)`
  width: auto;
  height: 170px;
  z-index: 30;
  user-select: none;
  margin-top: 0em;
  draggable='false'
  user-drag: none; 
  
  img {
    width: auto;
    height: 100%;
    user-select: none;
    draggable='false'
    user-drag: none; 
  }

  @media (max-width: 1366px) and (max-height: 768px) {
  
    img {
      height: 30%;
      margin-top: 3.5em;
      user-select: none;
    draggable='false'
    user-drag: none;
    }
`;

const SmallText = styled.span`
  color: #67c148;
  display: flex;
  height: 15%;
  weight: 15%;
  position: absolute;
  justify-content: center;
  top: 15px;
  left: 150px;
`;

export function JargonCard(props) {
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const rotateX = useTransform(y, [-100, 100], [30, -30]);
  const rotateY = useTransform(x, [-100, 100], [-30, 30]);

  return (
    <CardWrapper>
      <CardContainer
        style={{ x, y, rotateX, rotateY, z: 100 }}
        drag
        dragElastic={0.2000}
        dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
        whileTap={{ cursor: "grabbing" }}
      >
        <TopContainer>
        <SmallText> <Dice5/> </SmallText>
          <CardImageWrapper>
            <CardImage
              style={{ x, y, rotateX, rotateY, z: 100 }}
              drag
              dragElastic={0.2000}
              whileTap={{ cursor: "grabbing" }}
            >
              <img className="boardimgcard" src={"/img/Cards/Jargon.png"} />
            </CardImage>
          </CardImageWrapper>
          <CardText>Jargon</CardText>
        </TopContainer>
        <BottomContainer>
          <CardDetails />
        </BottomContainer>
      </CardContainer>
    </CardWrapper>
  );
}
