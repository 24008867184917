import React, { Component } from "react";
import axios from "axios";

class HomePageComponent extends Component {
  constructor() {
    super();
    this.state = {
      Stats: []
    };
  }

  componentDidMount() {
    //First get the gamestate
    axios.get("/api/ReadFile")
      .then((res) => {

      })
      .catch((error) => {
        console.log(error);
      });

  }



  render() {

    return (

      <div>
        <section>
          <div className="owl-carousel owl-theme-default hero-slider owl-nav-custom">
            {/* Hero Slide*/}
            <div className="hero-slide bg-size bg-center py-5" style={{ background: 'url(img/hero-banner-1.jpg)' }}>
              <div className="container text-white py-5 index-forward">
                <div className="row">
                  <div className="col-lg-7">
                    <h1>Play Big Bucks Game</h1>
                    <p className="lead">You are now able to play Big Bucks online with your friends and family.</p>
                    <ul className="list-inline">
                      <li className="list-inline-item"><a className="btn btn-primary" href="/CreateOffice">Open The Office</a></li>
                      <li className="list-inline-item"><a className="btn btn-outline-light" href="/JoinOffice">Enter the Office</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Hero Slide */}
            <div className="hero-slide bg-size bg-center py-5" style={{ background: 'url(img/hero-banner-2.jpg)' }}>
              <div className="container text-white py-5 index-forward">
                <div className="row">
                  <div className="col-lg-7">
                    <h1>Play Big Bucks Game</h1>
                    <p className="lead">A game that teaches you how to manage your finances with diverse and realistic events that could happen to you.</p>
                    <ul className="list-inline">
                      <li className="list-inline-item"><a className="btn btn-primary" href="/CreateOffice">Open The Office</a></li>
                      <li className="list-inline-item"><a className="btn btn-outline-light" href="/JoinOffice">Enter the Office</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Hero Slide */}
            <div className="hero-slide bg-size bg-center py-5" style={{ background: 'url(img/hero-banner-3.jpg)' }}>
              <div className="container text-white py-5 index-forward">
                <div className="row">
                  <div className="col-lg-7">
                    <h1>Learn More</h1>
                    <p className="lead"><a target="_blank" href="https://dobetterbusiness.co.za">Click Here </a> to learn more about our company.</p>
                    <ul className="list-inline">
                      <li className="list-inline-item"><a className="btn btn-primary" href="/CreateOffice">Open The Office</a></li>
                      <li className="list-inline-item"><a className="btn btn-outline-light" href="/JoinOffice">Enter The Office</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Features Section*/}
        <section className="py-5 bg-light">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-6 order-2 order-lg-1">
                <p className="h6 mb-1 text-uppercase text-primary mb-3">Board Game Main features</p>
                <h2 className="mb-4">Have fun while mastering your business finances, profitably!</h2>
                <p className="text-small text-muted">Small business owners with limited or no accounting knowledge learn to understand and manage their own business finances better.</p>
                <ul className="list-check list-unstyled row px-3 mb-4">
                  <li className="text-small text-muted col-lg-6 mb-2">Supervised by a person who is knowledgeable in accounting and financial concepts</li>
                  <li className="text-small text-muted col-lg-6 mb-2">Simulates business day-to-day transactions, accounting jargon and formulae</li>
                  <li className="text-small text-muted col-lg-6 mb-2">Improves business communication skills</li>
                  <li className="text-small text-muted col-lg-6 mb-2">Supports skills development</li>
                  <li className="text-small text-muted col-lg-6 mb-2">Supports teamwork dynamics and cooperation</li>
                  <li className="text-small text-muted col-lg-6 mb-2">Allows for trial and error based learning</li>
                </ul>
                <ul className="list-inline py-4 border-top border-bottom d-flex align-items-center">
                  <li className="list-inline-item px-5 mr-3 border-left">
                    <h5 className="mb-0">Rick Ed</h5>
                    <p className="small font-weight-normal text-muted mb-0">Chairman and founder </p>
                  </li>
                  <li className="list-inline-item pl-5 border-left">
                    <h5 className="mb-0">+27 83 244 8989</h5>
                    <p className="small font-weight-normal text-muted mb-0">Call to ask any question</p>
                  </li>
                </ul>
              </div>
              <div className="col-lg-5 ml-auto mb-4 mb-lg-0 order-1 order-lg-2">
                <div className="p-2">
                  <div className="BoardAnimation">
                    <div className="BoardAnimation:hover">
                      <img className="img-fluid" src="./img/BigBucksBoard.png" alt />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Testimonials Section*/}
        <section className="py-5">
          <div className="container py-5">
            <p className="h6 mb-1 text-uppercase text-primary mb-3">Testimonials from our clients</p>
            <div className="row mb-5">
              <div className="col-lg-5">
                <h2 className="mb-5">The Best Solutions For Developing Your Business</h2>
              </div>
              <div className="col-lg-7">
                <p className="text-muted">What people who have played the Big Bucks Game think...</p>
              </div>
            </div>
            <div className="testimonials-slider owl-carousel owl-theme">
              <div className="grow">
                <div className="grow:hover">
                  <div className="testimonial p-5 m-2">
                    <div className="d-flex align-items-center mb-4">
                      <div className="testimonial-img"><img className="img-fluid rounded-circle" src="img/user.png" alt="" /></div>
                      <div className="ml-3">
                        <h5 className="mb-0">Nomvuyo Bengane</h5>
                        <p className="small text-muted mb-1">BizOil Institute</p>
                        <ul className="small list-inline text-primary mb-0">
                          <li className="list-inline-item m-0"><i className="fas fa-star" /></li>
                          <li className="list-inline-item m-0"><i className="fas fa-star" /></li>
                          <li className="list-inline-item m-0"><i className="fas fa-star" /></li>
                          <li className="list-inline-item m-0"><i className="fas fa-star" /></li>
                          <li className="list-inline-item m-0"><i className="fas fa-star" /></li>
                        </ul>
                      </div>
                    </div>
                    <p className="text-muted text-small mb-0">“The Big Bucks simulation game is very interesting.
                    It simplifies business. It educates and it is fun to play. It is also very easy to understand.
                    I recommend it highly.”</p>
                  </div>
                </div>
              </div>
              <div className="grow">
                <div className="grow:hover">
                  <div className="testimonial p-5 m-2">
                    <div className="d-flex align-items-center mb-4">
                      <div className="testimonial-img"><img className="img-fluid rounded-circle" src="img/user.png" alt="" /></div>
                      <div className="ml-3">
                        <h5 className="mb-0">Ryan Makoni</h5>
                        <p className="small text-muted mb-1">Web Developer</p>
                        <ul className="small list-inline text-primary mb-0">
                          <li className="list-inline-item m-0"><i className="fas fa-star" /></li>
                          <li className="list-inline-item m-0"><i className="fas fa-star" /></li>
                          <li className="list-inline-item m-0"><i className="fas fa-star" /></li>
                          <li className="list-inline-item m-0"><i className="fas fa-star" /></li>
                          <li className="list-inline-item m-0"><i className="fas fa-star" /></li>
                        </ul>
                      </div>
                    </div>
                    <p className="text-muted text-small mb-0">“I found myself not only enjoying the game, but I learned a lot of things I never would have thought would occur in a real life business. It really opened my eyes to the reality of business finances.” </p>
                  </div>
                </div>
              </div>
              <div className="grow">
                <div className="grow:hover">
                  <div className="testimonial p-5 m-2">
                    <div className="d-flex align-items-center mb-4">
                      <div className="testimonial-img"><img className="img-fluid rounded-circle" src="img/user.png" alt="" /></div>
                      <div className="ml-3">
                        <h5 className="mb-0">Curriculum Manager</h5>
                        <p className="small text-muted mb-1">TSIBA ED</p>
                        <ul className="small list-inline text-primary mb-0">
                          <li className="list-inline-item m-0"><i className="fas fa-star" /></li>
                          <li className="list-inline-item m-0"><i className="fas fa-star" /></li>
                          <li className="list-inline-item m-0"><i className="fas fa-star" /></li>
                          <li className="list-inline-item m-0"><i className="fas fa-star" /></li>
                          <li className="list-inline-item m-0"><i className="fas fa-star" /></li>
                        </ul>
                      </div>
                    </div>
                    <p className="text-muted text-small mb-0">“The game added value to our students’ basic accounting understanding – even if it only introduced them to some basic and common financial terminology.
                     During the game, the groups were asked to argue on the pros and cons of certain financial scenarios and these arguments supported understanding and it motivated and encouraged the significance of sound financial innovation” </p>
                  </div>
                </div>
              </div>
              <div className="grow">
                <div className="grow:hover">
                  <div className="testimonial p-5 m-2">
                    <div className="d-flex align-items-center mb-4">
                      <div className="testimonial-img"><img className="img-fluid rounded-circle" src="img/user.png" alt="" /></div>
                      <div className="ml-3">
                        <h5 className="mb-0">Abdullah Mansuri</h5>
                        <p className="small text-muted mb-1">Web Developer</p>
                        <ul className="small list-inline text-primary mb-0">
                          <li className="list-inline-item m-0"><i className="fas fa-star" /></li>
                          <li className="list-inline-item m-0"><i className="fas fa-star" /></li>
                          <li className="list-inline-item m-0"><i className="fas fa-star" /></li>
                        </ul>
                      </div>
                    </div>
                    <p className="text-muted text-small mb-0">The Big Bucks game was an incredibly useful tool in learning the ins and outs of business. Playing the board game really does teach you a lot about how to manage your finances and I'm glad that I got to play it with my team</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default HomePageComponent;
