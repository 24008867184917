import React, { Component } from "react";
import axios from "axios";
import Dice from 'react-dice-roll';
import moment from "moment";
import LoadingBar from 'react-top-loading-bar';
import ReactCountdownClock from "react-countdown-clock"
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { DamageCard } from "./Cards/DamageCard";
import { DecisionCard } from "./Cards/DecisionCard";
import { DilemmaCard } from "./Cards/DilemmaCard";
import { FormulaCard } from "./Cards/FormulaCard";
import { JargonCard } from "./Cards/JargonCard";
import { SurpriseCard } from "./Cards/SurpriseCard";
import { DiceCard } from "./Cards/DiceCard";
import ReactTooltip from 'react-tooltip';
import { resolveMotionValue } from "framer-motion";


class GameComponent extends Component {
  constructor() {
    super();
    this.state = {
      GameID: "",
      PlayerName: "",
      BoardData: [],
      Player: {},
      Icons: ["fas fa-2x fa-user-tie", "fas fa-2x fa-dollar-sign", "fas fa-2x fa-piggy-bank", "fas fa-2x fa-money-bill-alt"],
      IconColors: ["blue", "red", "green", "black"],
      Players: [],
      TurnText: "Wait Your Turn",
      IsMyTurn: false,
      TimerSeconds: 60,
      TimerPaused: true,
      TimerColor: "red",
      TimerChanged: false,
      NumberRolledText: "",
      NumberRolled: 0,
      RolledAlready: false,
      WarningMessage: null,
      GridCardClicked: false,
      GameHistory: [],
      GameState: null,
      ErrorMessage: null,
      ModalTitle: "Please Roll The Die First",
      ModalContent: "",
      ModalContentHidden: "",
      CardScenario: {
        PlayerName: "Player",
        Player: "1",
        COU: 0,
        MITB: 0,
        WOS: 0,
      },
      Card: [],
      CardTypeHistory: ""
    };
    this.MyLoadingBar = React.createRef();
    this.MyScenarioImage = React.createRef();
  };

  componentDidMount() {

    this.MyLoadingBar.current.continuousStart();

    //Get parameters
    this.setState({
      GameID: this.props.match.params.GameID,
      PlayerName: this.props.match.params.PlayerName.toUpperCase()
    }, () => {

      //Get the board data
      let PromGetBoardData = axios.get("/api/GetBoard").then((Result) => {
        this.setState({ BoardData: Result.data });
      }).catch((Error) => {
        alert("error1");
      });

      //Get GameState
      const GameID = this.state.GameID;
      let PromGetGameState = axios.post("/api/GetGameState", { GameID }).then((Result) => {
        if (Result.data.Success) {
          this.setState({ GameState: Result.data.GameData }, () => {
            if (this.state.GameState.GameStatus === 0) {
              this.props.history.push("/");
            };
            if (this.state.GameState.GameStatus === 2) {
              //game end page
              this.props.history.push("/EndGame/" + GameID + "/100");
            };
          });
        } else {
          alert(Result.data.Reason);
        };

      }).catch((Error) => {
        alert("error2" + Error)
      });

      //Get Player
      const frmPlayerName = this.state.PlayerName;
      const frmGameID = this.state.GameID;
      let PromGetPlayer = axios.post("/api/GetPlayer", { frmGameID, frmPlayerName }).then((Result) => {
        if (Result.data.Success) {
          this.setState({ Player: Result.data.Player })
        } else {
          alert(Result.data.Reason)
        };
      }).catch((Error) => {
        alert(Error);
      });

      //Get all players also
      let PromPlayers = axios.post("/api/GetGamePlayers", { GameID }).then((Result) => {
        if (Result.data.Success) {
          this.setState({ Players: Result.data.Players });
        } else {
          this.setState({
            Error:
              <div className="alert alert-danger">{Result.data.Reason}</div>
          });
          this.MyLoadingBar.current.complete();
        };
      });

      Promise.all([PromGetBoardData, PromGetGameState, PromGetPlayer, PromPlayers]).then(() => {
        this.MyLoadingBar.current.complete();

        //Ref parent (this)
        let Parent = this;

        //Create our game loop to loop and get data every 4 seconds
        setInterval(function () {

          Parent.MyLoadingBar.current.continuousStart();

          const GameID = Parent.state.GameID;

          //Update game state
          let PromGameState = axios.post("/api/GetGameState", { GameID }).then((Result) => {
            if (Result.data.Success) {
              Parent.setState({ GameState: Result.data.GameData });
            };
          });

          //Update players
          let PromPlayers = axios.post("/api/GetGamePlayers", { GameID }).then((Result) => {
            if (Result.data.Success) {
              Parent.setState({ Players: Result.data.Players });
            };
          });

          //update my player as well
          //Get Player
          const frmPlayerName = Parent.state.PlayerName;
          const frmGameID = Parent.state.GameID;
          let PromGetPlayer = axios.post("/api/GetPlayer", { frmGameID, frmPlayerName }).then((Result) => {
            if (Result.data.Success) {
              Parent.setState({ Player: Result.data.Player })
            } else {
              alert(Result.data.Reason)
            };
          }).catch((Error) => {
            alert(Error);
          });

          let PromEndGameCheck = axios.post("/api/EndGameCheck", { frmGameID }).then((Result) => { })


          //Update game history here
          const OfficeCode = Parent.state.GameState.OfficeCode;
          let PromGameHistory = axios.post("/api/GetGameHistory", { OfficeCode }).then((Result) => {
            if (Result.data === null) {
              //display error message
              Parent.setState({
                ErrorMessage: <div className="alert alert-warning"> Game History Could Not Be Found </div>
              })
            } else {
              Parent.setState({ GameHistory: Result.data })
            }
          });


          Promise.all([PromGameState, PromPlayers, PromGetPlayer, PromGameHistory, PromEndGameCheck]).then(() => {

            //check
            if (Parent.state.GameState.GameStatus === 2) {
              Parent.props.history.push("/EndGame/" + Parent.state.GameState._id + "/100");
            }
            //Check my turn
            if (Parent.state.GameState.CurrentPlayer === Parent.state.Player.PlayerNumber) {
              //its my turn
              Parent.setState({
                IsMyTurn: true,
                TurnText: "Please Click On The Die",
                //Timer stuff
                TimerPaused: false,
                TimerColor: "Orange"
              });

              if (!Parent.state.TimerChanged) {
                Parent.setState({ TimerSeconds: Parent.state.GameState.GameTurnTime * 60, TimerChanged: true })
              };

            } else {
              //not my turn
              Parent.setState({
                IsMyTurn: false,
                TurnText: "Please Wait Your Turn",
                //Timer stuff
                TimerPaused: true,
                TimerColor: "Red",
                TimerSeconds: 0,
                TimerChanged: false,
                RolledAlready: false,
                GridCardClicked: false,
                WarningMessage: null,
                ModalTitle: "Please Roll The Die First",
                ModalContent: "",
                ModalContentHidden: "",
                NumberRolledText: ""
              });
            };
            Parent.MyLoadingBar.current.complete();
          });



        }, 4000);//End game loop


      });//End promise all



    });//End
  };//end component did mount

  //New functions
  RollTheDice() {
    //This is the first function thats called
    //Check if its our turn
    if (this.state.GameState.CurrentPlayer !== this.state.Player.PlayerNumber) {
      return;
    };

    //Am i alive - this cant run because it wont be the players turn if they dead, but added anyway i guess.
    if (!this.state.Player.IsAlive) {
      return;
    };

    if (this.state.RolledAlready) {
      return;
    };

    this.setState({ RolledAlready: true });

    //this.MyScenarioImage.current.src = "/img/diceroll.gif";

    setTimeout(() => {
      const iRandom = Math.floor(Math.random() * 6) + 1;
      this.setState({
        NumberRolledText: "You Rolled: " + iRandom,
        NumberRolled: iRandom,
      });

      const DiceNumber = iRandom;
      const PlayerNumber = this.state.GameState.CurrentPlayer;
      const OfficeCode = this.state.GameState.OfficeCode;
      const frmPlayerName = this.state.PlayerName;

      //Adds player and number rolled to game history
      var sEventText = "Rolled " + DiceNumber;
      axios.post("/api/CreateGameHistory", { OfficeCode, frmPlayerName, sEventText }).then((Result) => {
      }).catch((error) => {
        console.log(error);
      });


      //Move player on the board here 
      axios.post("/api/PlayTurnOne", { DiceNumber, PlayerNumber, OfficeCode, frmPlayerName}).then((Result) => {
        if (Result.data.Success === true) {

          switch (DiceNumber) {
            case 1:
              this.setState({
                WarningMessage:
                  <div className="alert alert-success">Please Choose a Damage Card</div>,
                  CardTypeHistory: "Damage - "
              });
              break;
            case 2:
              this.setState({
                WarningMessage:
                  <div className="alert alert-success">Please Choose a Decision Card</div>,
                  CardTypeHistory: "Decision - "
              })
              break;
            case 3:
              this.setState({
                WarningMessage:
                  <div className="alert alert-success">Please Choose a Dilemma Card</div>,
                  CardTypeHistory: "Dilemma - "
              })
              break;
            case 4:
              this.setState({
                WarningMessage:
                  <div className="alert alert-success">Please Choose a Formula Card</div>,
                  CardTypeHistory: "Formula - "
              })
              break;
            case 5:
              this.setState({
                WarningMessage:
                  <div className="alert alert-success">Please Choose a Jargon Card</div>,
                  CardTypeHistory: "Jargon - "
              });
              break;
            case 6:
              this.setState({
                WarningMessage:
                  <div className="alert alert-success">Please Choose a Surprise Card</div>,
                  CardTypeHistory: "Surprise - "
              });
              break;
          };

        } else {
          console.log("Error while requesting play turn one");
        };
      })

    }, 1500);

  };

  GridCardClick(CardType) {

    if (this.state.GridCardClicked) {
      return;
    };

    if (!this.state.RolledAlready) {
      return;
    };

    if (this.state.NumberRolled !== CardType) {
      this.setState({
        ModalTitle: "Warning",
        ModalContent: "Please Choose The Correct Card"
      });
      return;
    };

    this.setState({ GridCardClicked: true });

    //Get random card from database
    const frmCardType = CardType;
    const OfficeCode = this.state.GameState.OfficeCode;
    const PlayerNumber = this.state.GameState.CurrentPlayer;
    const frmPlayerName = this.state.PlayerName;

    axios.post("/api/GetGridCard", { frmCardType, OfficeCode, PlayerNumber }).then((Result) => {

      if (Result.data.Success === true) {
        this.setState({
          ModalTitle: "View Your Card",
          ModalContent: Result.data.Card.Text,
          ModalContentHidden: Result.data.Card.Answer
        });

        //Adds player card to game history
        var GameHistoryAnswer = "";
        if (Result.data.Card.Answer != null){
          GameHistoryAnswer = " - Answer - " + Result.data.Card.Answer;
        };

        console.log(this.state.CardTypeHistory);
        var sEventText = "Card drawn: " + this.state.CardTypeHistory + Result.data.Card.Text + GameHistoryAnswer;
        axios.post("/api/CreateGameHistory", { OfficeCode, frmPlayerName, sEventText }).then((Result) => {
        }).catch((error) => {
          console.log(error);
        });

      } else {
        this.setState({
          WarningMessage:
            <div className="alert alert-warning">{Result.data.Reason}</div>
        });
      }

    });

  };

  EndMyTurn() {
    //ends the turn and moves to next available player
    const OfficeCode = this.state.GameState.OfficeCode
    const PlayerNumber = this.state.GameState.CurrentPlayer

    axios.post("/api/PlayTurnThree", { OfficeCode, PlayerNumber }).then((Result) => {
      if (Result.data.Success === true) {
        axios.post("/api/SkipPlayer", { OfficeCode }).then((Result) => {
          if (Result.data.Success === false) {
            alert("An unknown error has occured! Please ask Business Advisor to Skip Player.")
          }
        })
      } else {
        alert("An unknown error has occured! Please ask Business Advisor to Skip Player.")
      }
    })
  }

  UpdateScenarioModal(Player) {
    this.setState({
      CardScenario: {
        PlayerName: Player.PlayerName,
        Player: Player.PlayerNumber,
        COU: Player.CustomersOweUs,
        MITB: Player.MoneyInTheBank,
        WOS: Player.WeOweSuppliers
      }
    });
  };

  GameTurnOver() {
    //this will go to the next player even though the player is not finished lol
    const OfficeCode = this.state.GameState.OfficeCode;
    const frmPlayerName = this.state.PlayerName;
    
    //Adds player card to game history
    var sEventText = "has ran out of time and has been skipped.";
    axios.post("/api/CreateGameHistory", { OfficeCode, frmPlayerName, sEventText }).then((Result) => {
    }).catch((error) => {
      console.log(error);
    });

    axios.post("/api/SkipPlayer", { OfficeCode }).then((Result) => {
      if (Result.Success === true) {
        this.setState({
          WarningMessage:
            <div className="alert alert-success">A player has been skipped due to taking too long</div>
        });
      }
    })
  }

  LeaveGame() {

    const OfficeCode = this.state.GameState.OfficeCode;
    const CurrentPlayer = this.state.GameState.CurrentPlayer
    const PlayerName = this.props.match.params.PlayerName.toUpperCase();

    axios.post("/api/KillPlayer", { OfficeCode, CurrentPlayer }).then((Result) => {
      if (Result.data.Success === true) {

        var sEventText = "has been removed from the game ";
        axios.post("/api/CreateGameHistory", { OfficeCode, PlayerName, sEventText }).then((Result) => {
        }).catch((error) => {
          console.log(error);
        });

        axios.post("/api/SkipPlayer", { OfficeCode, CurrentPlayer }).then((Result) => {
          if (Result.data.Success === true) {
            this.setState({ MessageDiv: <div className="alert alert-warning center">{"Player " + CurrentPlayer + " has been removed from the game"}</div> })
          }
        })

      }
    })
  };

  

  render() {

    <div>
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLongTitle">CARD</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div><p>{this.state.Card.Text}</p></div>
        <div className="Hidden">{this.state.Card.Answer}</div>
      </div>
    </div>;

    const BoardDataObject = this.state.BoardData.map((Obj, iLoop) => (
      <div key={Obj._id} className="GridInfo center">
        <img className="boardimg " src={"/img/BigBucks/" + (iLoop + 1) + ".PNG"}></img>
        <div>{this.state.Players.map((Player, iLoop2) => (
          (iLoop + 1) === Player.PlayerPosition ? <td><i style={{ color: this.state.IconColors[iLoop2] }} className={this.state.Icons[iLoop2]}></i></td> : ""
        ))}</div>
      </div>
    ));

    const PlayersData = this.state.Players.map((Player, iLoop) => (
      <tr>
        <th>{Player.PlayerNumber}</th>
        <td>{Player.PlayerName}</td>
        <td><i style={{ color: this.state.IconColors[iLoop] }} className={this.state.Icons[iLoop]}></i></td>
        <td>{Player.IsAlive ? "Alive" : "Dead"}{(iLoop + 1) === this.state.GameState.CurrentPlayer ? " - Playing" : ""}</td>
        <td>{Player.NetWorth}</td>        
        <td>{<button data-toggle="modal" onClick={() => this.UpdateScenarioModal(Player)} data-target="#exampleModal" className="btn-sm btn-outline-primary" type="button"><i className="fas fa-money-check"></i></button>}</td>
      </tr>
    ));

    //Render game history here
    const GameHistoryData = this.state.GameHistory.map((HistoryObj, iloop) => (
      <table className="tableGH table" border="1">
        <tr>
          <td>{HistoryObj.PlayerName + " - " + HistoryObj.Event}</td>
        </tr>
      </table>
    ));

    return (
      <div>
        <LoadingBar color="orange" height={10} ref={this.MyLoadingBar} shadow={true} />
        <br></br>
        <div className="GameContainer GameSpacing">
          <div className="row">
              <div className="GameCard">
                <h3>Business Partners</h3>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Token</th>
                      <th scope="col">Status</th>
                      <th scope="col">Net worth</th>
                      <th scope="col">Record</th>
                    </tr>
                  </thead>
                  <tbody>
                    {PlayersData}
                  </tbody>
                </table>
              </div>
            <div className="col-10 col-sm-10 col-lg-4 col-md-6">
              <div className="GameCard">
                {/* Game history table */}
                <h3>Game History</h3>
                <table className="table tableGH tableGH-scrollbar tableGH-wrapper-scroll-y">
                  <tbody>
                    {GameHistoryData}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-10 col-sm-6 col-lg-3 col-md-5">
              <div className="GameCard">
                <div className="GameCentre">
                  <h3>{this.state.TurnText}</h3>
                </div>
                <div className="GameCentre">
                  <ReactCountdownClock
                    seconds={this.state.TimerSeconds}
                    paused={this.state.TimerPaused}
                    color={this.state.TimerColor}
                    alpha={0.9}
                    size={130}
                    onComplete={() => this.GameTurnOver()}
                  />
                </div>
              </div>
              <div className="row GameCentre">
                
                {/* cheat sheet/help tool tips */}
                <div className="side container center">
                  <a data-tip data-for="sadFace" className="dot center">
                    Cheat Sheet
                  </a>
                  <ReactTooltip id="sadFace" type="warning" effect="solid">
                    <p><b>Creditor</b>: a person or company to whom money is owing.</p>
                    <p><b>Debtor</b>: a person, country, or organization that owes money.</p>
                    <p><b>Capital</b>: wealth in the form of money or other assets owned by a person or organization or available for a purpose such as starting a company or investing.</p>
                  </ReactTooltip>
                </div>

                <h4>{this.state.NumberRolledText}</h4>
                <h5 className="center">{this.state.WarningMessage}</h5>
              </div>
            </div>
          </div >
        </div>
        <br></br>

        <div className="GridContainer">
          <div onClick={() => this.RollTheDice()}>
            <div className="GridCards boardimg">
              <DiceCard />
            
            </div>

          </div>
          <div onClick={() => this.GridCardClick(1)} data-toggle="modal" data-target="#exampleModalCenter">
            <div className="GridCards boardimg"> <DamageCard /></div>
          </div>
          <div onClick={() => this.GridCardClick(2)}>
            <div className="GridCards boardimg" data-toggle="modal" data-target="#exampleModalCenter"> <DecisionCard /></div>
          </div>
          <div onClick={() => this.GridCardClick(3)}>
            <div className="GridCards boardimg" data-toggle="modal" data-target="#exampleModalCenter"> <DilemmaCard /></div>
          </div>
          <div onClick={() => this.GridCardClick(4)}>
            <div className="GridCards boardimg" data-toggle="modal" data-target="#exampleModalCenter"> <FormulaCard /></div>
          </div>
          <div onClick={() => this.GridCardClick(5)}>
            <div className="GridCards boardimg" data-toggle="modal" data-target="#exampleModalCenter"> <JargonCard /></div>
          </div>
          <div onClick={() => this.GridCardClick(6)}>
            <div className="GridCards boardimg" data-toggle="modal" data-target="#exampleModalCenter"> <SurpriseCard /></div>
          </div>
          {BoardDataObject}
        </div> {/* grid Container */}

        <div className="center">
          <button className="btn btn-danger" onClick={()=> this.LeaveGame()}>Leave Game</button>

        </div>


        {/* MODAL FOR GRIDCARDS */}
        <div className="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">{this.state.ModalTitle}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>{this.state.ModalContent}</p>
                <p className="Hidden">{this.state.ModalContentHidden}</p>
              </div>
              <div className="modal-footer">
                {this.state.GridCardClicked ? <button data-dismiss="modal" onClick={() => this.EndMyTurn()} type="button" className="btn btn-danger">End Turn</button> : <div></div>}
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{"Viewing Scenario For: " + this.state.CardScenario.PlayerName}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item"><strong>Customers Owe Us: </strong>{"BB -> " + this.state.CardScenario.COU}</li>
                  <li className="list-group-item"><strong>Money In The Bank: </strong>{"BB -> " + this.state.CardScenario.MITB}</li>
                  <li className="list-group-item"><strong>We Owe Suppliers: </strong>{"BB -> " + this.state.CardScenario.WOS}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default GameComponent;