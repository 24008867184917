import { motion, useMotionValue, useTransform } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { CardDetails } from "./CardDetails";
import { Dice1 } from '@styled-icons/bootstrap'

const CardWrapper = styled.div`
  width: 100%;
  perspective: 2000;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1366px) and (max-height: 768px) {
  
    max-width: 7em;
    
}
`;

// controlls the card sizing and style
const CardContainer = styled(motion.div)`
  width: 215px;
  height: 350px;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  box-shadow: 0 2px 7px 1px rgba(31, 31, 31, 0.2);
  background-color: #102d4a;
  color: #fff;
  position: relative;
  cursor: grab;

  @media (max-width: 1366px) and (max-height: 768px) {
  
    max-width: 8em;
    max-height: 16em;
}
`;

// controls the entire card
const TopContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1.2;
  position: relative;
  align-items: center;
  justify-content: flex-end;
`;

// controlls the view botton and bottom text
const BottomContainer = styled.div`  
  display: flex;
  flex: 0.8;
  padding: 0 1em;
`;

const CardText = styled.div`
  color: #F27B26;
  text-transform: uppercase;
  z-index: 10;
  font-size: 250%;
  font-weight: 500;

  @media (max-width: 1366px) and (max-height: 768px) {
  
    max-width: 7em;
    font-size: 100%;
    font-weight: 250;
}
`;

const CardImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MediumText = styled(motion.div)`
  width: auto;
  margin-top: 0em;
  color: #F27B26;
  display: flex;
  height: 60%;
  position: absolute;
  justify-content: center;
  padding-bottom: 15%;
`;

export function DiceCard(props) {
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const rotateX = useTransform(y, [-100, 100], [30, -30]);
  const rotateY = useTransform(x, [-100, 100], [-30, 30]);

  return (
    <CardWrapper>
      <CardContainer
        style={{ x, y, rotateX, rotateY, z: 100 }}
        drag
        dragElastic={0.2000}
        dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
        whileTap={{ cursor: "grabbing" }}
      >
        <TopContainer>
          <CardImageWrapper>
            <MediumText
              whileHover={{
                scale: [1, 2, 2, 1, 1],
                rotate: 180
              }}
              transition={{
                duration: 3,
                delay: 0.01,
                ease: "easeInOut",
                type: "spring",
                damping: 15,
                mass: 0.75,
                stiffness: 150,
              }}
            >
              <Dice1/> 
            </MediumText>
          </CardImageWrapper>
          <CardText>Dice</CardText>
        </TopContainer>
        <BottomContainer>
          <CardDetails />
        </BottomContainer>
      </CardContainer>
    </CardWrapper>
  );
}
