import React, { Component } from "react";
import axios from "axios";

class HowToPlayComponent extends Component {
  constructor() {
    super();
    this.state = {
      GameState: [],
      Changed: "false"
    };
    setTimeout(() => {
      this.setState({Changed: "true"});
    }, 2000);
  }

  componentDidMount() {
    axios.get("/api/GetData").then((res) => {
        this.setState({ GameState: res.data });
      }).catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          //this.props.history.push("/");
        }
      });      

  }



  render() {
    const GameStateObject = this.state.GameState.map((GS, iLoop) => (
      <div className="alert alert-success">{GS.LobbyCode}</div>
    ));

    return (

      //form for creating game session

      <div>

<section className="py-5 bg-light">
          <div className="container py-5">
            <header className="mb-5 text-center">
              <div className="row">
                <div className="col-lg-7 mx-auto">
                  <h2>How To Play</h2>
                  <p className="text-muted">Big Bucks is a small business simulation game. It is intended to help SMME’s who don't know bookkeeping to learn enough to manage and understand their own business finances.</p>
                </div>
              </div>
            </header>
            <div className="row align-items-stretch">
              <div className="col-lg-4 mb-4 mb-lg-0">
                <div className="bg-white h-100">
                  <div className="bg-primary px-4 py-3 d-inline-block">
                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                  </div>
                  <div className="px-5 pt-0 pb-5 bg-white text-center">
                    <h2 className="h4 mb-3">Player Count</h2>
                    <p className="text-muted text-small mb-0">Four ‘businesses’ with two ‘partners’ each can play at a time along its edges, the board has 28 days in 4 weeks.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-4 mb-lg-0">
                <div className="bg-white h-100">
                  <div className="bg-primary px-4 py-3 d-inline-block">
                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                  </div>
                  <div className="px-5 pt-0 pb-5 bg-white text-center">
                    <h2 className="h4 mb-3">Scenario</h2>
                    <p className="text-muted text-small mb-0">Each business will be given a random scenario. The scenario will include how much money their business have in their bank account; how much ow much its customers owe it, and how much it owes to its suppliers.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="bg-white h-100">
                  <div className="bg-primary px-4 py-3 d-inline-block">
                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                  </div>
                  <div className="px-5 pt-0 pb-5 bg-white text-center">
                    <h2 className="h4 mb-3">Money Record</h2>
                    <p className="text-muted text-small mb-0">As they move from day to day, transactions between the businesses, their customers and suppliers are recorded and totalled up each ‘month’. Players will need to track their history.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row padding-top">
              <div className="col-lg-7 mx-auto text-center">
                <p className="text-muted">The game simulates players’ own business transactions, while introducing them to essential accounting jargon and formulae, so that they build confidence that they can manage their business money profitably</p>
              </div>
            </div>
          </div>
        </section>
        <section className="py-5">
          <div className="container py-5">
            <h2>Frequently asked questions</h2>
            <p />
            <div className="accordion" id="accordionExample">
              <div className="card rounded-0 border-0 mb-1">
                <div className="card-header p-0 border-0 bg-white rounded-0" id="headingOne">
                  <h6 className="mb-0"><a className="reset-anchor w-100 px-4 py-3 d-block" data-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      How do you win?
                    </a></h6>
                </div>
                <div className="collapse show" id="collapseOne" aria-labelledby="headingOne" data-parent="#accordionExample">
                  <div className="card-body">
                    <p className="text-small text-muted">The winner is the business that ends with the best profitability ratio. The facilitator  may  apply  to  <a target="_blank" href="https://dobetterbusiness.co.za">DoBetter.Business</a> for an accomplishment certficate for the winning business.</p>
                  </div>
                </div>
              </div>
              <div className="card rounded-0 border-0 mb-1">
                <div className="card-header p-0 border-0 bg-white rounded-0" id="headingTwo">
                  <h6 className="mb-0"><a className="reset-anchor w-100 px-4 py-3 d-block collapsed" data-toggle="collapse" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      What happens if I have no money left?
                    </a></h6>
                </div>
                <div className="collapse" id="collapseTwo" aria-labelledby="headingTwo" data-parent="#accordionExample">
                  <div className="card-body">
                    <p className="text-small text-muted">If a business’ bank balance falls below 0, the business is broke (bankrupt) and will not be able to play any longer.</p>
                  </div>
                </div>
              </div>
              <div className="card rounded-0 border-0">
                <div className="card-header p-0 border-0 bg-white rounded-0" id="headingThree">
                  <h6 className="mb-0"><a className="reset-anchor w-100 px-4 py-3 d-block collapsed border-bottom" data-toggle="collapse" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      How often should I play?
                    </a></h6>
                </div>
                <div className="collapse" id="collapseThree" aria-labelledby="headingThree" data-parent="#accordionExample">
                  <div className="card-body">
                    <p className="text-small text-muted">To be of suffcient value to the players, they should play the game at least four times, ideally at weekly intervals.</p>
                  </div>
                </div>
              </div>
              <div className="card rounded-0 border-0">
                <div className="card-header p-0 border-0 bg-white rounded-0" id="headingThree">
                  <h6 className="mb-0"><a className="reset-anchor w-100 px-4 py-3 d-block collapsed border-bottom" data-toggle="collapse" href="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                      Do we need a facilitator when playing?
                    </a></h6>
                </div>
                <div className="collapse" id="collapseFour" aria-labelledby="headingThree" data-parent="#accordionExample">
                  <div className="card-body">
                    <p className="text-small text-muted">The game must be facilitated by a person who is knowledgeable in accounting and who explains financial and accounting concepts in every-day language.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
      </div>
    );
  }
}

export default HowToPlayComponent;
