import React, { Component } from "react";
import axios from "axios";
import LoadingBar from 'react-top-loading-bar';

class BusinessAdvisorControlPanelComponent extends Component {
  constructor() {
    super();
    this.state = {
      GameButtonText: "Start Game",
      GameBtnDisabled: false,
      GameStartStuff: false,
      GameStartedStuff: true,
      PlayersMsg: "There are no players in the lobby yet.",
      GameID: "",
      PlayerName: "",
      Error: null,
      GameState: {},
      OldPlayer: 0,
      GameHistory: [],
      BoardData: [],
      NetWorth: 0,
      Players: [],
      MessageDiv: <div></div>,
      Icons: ["fas fa-2x fa-user-tie", "fas fa-2x fa-dollar-sign", "fas fa-2x fa-piggy-bank", "fas fa-2x fa-money-bill-alt"],
      IconColors: ["blue", "red", "green", "black"],
      CardScenario: {
        PlayerName: "Player",
        Player: "1",
        COU: 0,
        MITB: 0,
        WOS: 0,
      }
    };
    this.MyLoadingBar = React.createRef();

  };



  componentDidMount() {
    this.setState({
      GameID: this.props.match.params.GameID,

    }, () => {

      //Get the board data
      let PromGetBoardData = axios.get("/api/GetBoard").then((Result) => {
        this.setState({ BoardData: Result.data });
      }).catch((Error) => {
        alert("error1");
      });

      //Get GameState
      const GameID = this.state.GameID;
      let PromGetGameState = axios.post("/api/GetGameState", { GameID }).then((Result) => {
        if (Result.data.Success) {
          this.setState({
             GameState: Result.data.GameData ,
             TimerSeconds: Result.data.GameData.GameTurnTime * 60
            }, () => {
          });
        } else {
          alert(Result.data.Reason);
        };
      }).catch((Error) => {
        alert("error2" + Error)
      });

      //Get all players also
      let PromPlayers = axios.post("/api/GetGamePlayers", { GameID }).then((Result) => {
        if (Result.data.Success) {
          this.setState({ Players: Result.data.Players });
        } else {
          this.setState({
            Error:
              <div className="alert alert-danger">{Result.data.Reason}</div>
          });
          this.MyLoadingBar.current.complete();
        };
      });

      Promise.all([PromGetBoardData, PromGetGameState, PromPlayers]).then(() => {
        this.MyLoadingBar.current.complete();

        //Ref parent (this)
        let Parent = this;

        //Create our game loop to loop and get data every 4 seconds
        setInterval(function () {

          Parent.MyLoadingBar.current.continuousStart();

          const GameID = Parent.state.GameID;

          //Update game state
          let PromGameState = axios.post("/api/GetGameState", { GameID }).then((Result) => {
            if (Result.data.Success) {
              Parent.setState({ GameState: Result.data.GameData });
            };
          });

          //Update players
          let PromPlayers = axios.post("/api/GetGamePlayers", { GameID }).then((Result) => {
            if (Result.data.Success) {
              Parent.setState({ Players: Result.data.Players });
            };
          });

          //update my player as well
          const frmGameID = Parent.state.GameID;
          let PromEndGameCheck = axios.post("/api/EndGameCheck", { frmGameID }).then((Result) => { });

          //Update game history here
          const OfficeCode = Parent.state.GameState.OfficeCode;
          let PromGameHistory = axios.post("/api/GetGameHistory", { OfficeCode }).then((Result) => {
            if (Result.data === null) {
              //display error message
              Parent.setState({
                ErrorMessage: <div className="alert alert-warning"> Game History Could Not Be Found </div>
              })
            } else {
              Parent.setState({ GameHistory: Result.data })
            }
          });          


          Promise.all([PromGameState, PromPlayers, PromEndGameCheck, PromGameHistory]).then(() => {

            //check
            if (Parent.state.GameState.GameStatus === 2) {
              Parent.props.history.push("/EndGame/" + Parent.state.GameState._id + "/159");
            }

            //check if there are any players in the lobby
            if (Parent.state.Players.length > 0) {
              Parent.setState({ PlayersMsg: "" });
            }

            //check if the game has started to refresh the page
            if (Parent.state.GameState.GameStatus === 1) {
              Parent.setState({ GameStartStuff: true, GameStartedStuff: false })
            }

            Parent.MyLoadingBar.current.complete();
          });

        }, 4000);//End game loop

      });//End promise all

    });//End
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
  };

  UpdateScenarioModal(Player) {
    this.setState({
      CardScenario: {
        PlayerName: Player.PlayerName,
        Player: Player.PlayerNumber,
        COU: Player.CustomersOweUs,
        MITB: Player.MoneyInTheBank,
        WOS: Player.WeOweSuppliers
      }
    });
  };

  startGame() {
    //call start game 
    const OfficeCode = this.state.GameState.OfficeCode;
    axios.post("/api/StartTheGame", { OfficeCode }).then((Result) => {

      if (Result.data.Success === true) {
        //disable button
        this.setState({ GameBtnDisabled: true, MessageDiv: <div className="alert alert-success center">The game has started</div> })
        //hide stuff.... dont know what yet tho
        this.setState({ GameStartStuff: true, GameStartedStuff: false });
      } else {
        this.setState({ GameBtnDisabled: true, MessageDiv: <div className="alert alert-danger center">The game did not start.</div> })
      }
    })

    const frmPlayerName = "Business Advisor";
    var sEventText = "has Started the game.";
    axios.post("/api/CreateGameHistory", { OfficeCode, frmPlayerName, sEventText }).then((Result) => {
    }).catch((error) => {
      console.log(error);
    });

  };

  EndGame() {
    const OfficeCode = this.state.GameState.OfficeCode;
    const frmPlayerName = "Business Advisor";


    var sEventText = "has ended the game.";
    axios.post("/api/CreateGameHistory", { OfficeCode, frmPlayerName, sEventText }).then((Result) => {
    }).catch((error) => {
      console.log(error);
    });

    axios.post("/api/EndGame", { OfficeCode }).then((Result) => {
      if (Result.data.Success === true) {
        this.setState({ GameBtnDisabled: true, MessageDiv: <div className="alert alert-warning center">The game has been ended.</div> })
      }
    })
  }

  skipPlayer() {
    const OfficeCode = this.state.GameState.OfficeCode;
    const CurrentPlayer = this.state.GameState.CurrentPlayer;
    const frmPlayerName = "Player " +  CurrentPlayer;



    axios.post("/api/SkipPlayer", { OfficeCode, CurrentPlayer }).then((Result) => {
      if (Result.data.Success === true) {
      
        var sEventText = "has been skipped ";
        axios.post("/api/CreateGameHistory", { OfficeCode, frmPlayerName, sEventText }).then((Result) => {
        }).catch((error) => {
          console.log(error);
        });

        this.setState({ MessageDiv: <div className="alert alert-warning center">{"Player " + CurrentPlayer + " has been skipped"}</div> })
      }
    })
  };

  killPlayer() {
    const OfficeCode = this.state.GameState.OfficeCode;
    const CurrentPlayer = this.state.GameState.CurrentPlayer
    const frmPlayerName = "Player " +  CurrentPlayer;

    axios.post("/api/KillPlayer", { OfficeCode, CurrentPlayer }).then((Result) => {
      if (Result.data.Success === true) {

        var sEventText = "has been removed from the game ";
        axios.post("/api/CreateGameHistory", { OfficeCode, frmPlayerName, sEventText }).then((Result) => {
        }).catch((error) => {
          console.log(error);
        });

        axios.post("/api/SkipPlayer", { OfficeCode, CurrentPlayer }).then((Result) => {
          if (Result.data.Success === true) {
            this.setState({ MessageDiv: <div className="alert alert-warning center">{"Player " + CurrentPlayer + " has been removed from the game"}</div> })
          }
        })

      }
    })
  };

  awardMoney() {
    const OfficeCode = this.state.GameState.OfficeCode;
    const CurrentPlayer = this.state.GameState.CurrentPlayer;
    const frmPlayerName = "Player " +  CurrentPlayer;

    axios.post("/api/AwardMoney", { OfficeCode, CurrentPlayer }).then((Result) => {
      if (Result.data.Success === true) {
        
        var sEventText = "has been awarded B1000 ";
        axios.post("/api/CreateGameHistory", { OfficeCode, frmPlayerName, sEventText }).then((Result) => {
        }).catch((error) => {
          console.log(error);
        });

        this.setState({ MessageDiv: <div className="alert alert-success center">{"Player " + CurrentPlayer + " has been awared B1000 to Money In The Bank"}</div> })
      }
    })
  };

  punishMoney() {
    const OfficeCode = this.state.GameState.OfficeCode;
    const CurrentPlayer = this.state.GameState.CurrentPlayer;
    const frmPlayerName = "Player " +  CurrentPlayer;

    axios.post("/api/PunishMoney", { OfficeCode, CurrentPlayer }).then((Result) => {
      if (Result.data.Success === true) {

        var sEventText = "has had B1000 deducted";
        axios.post("/api/CreateGameHistory", { OfficeCode, frmPlayerName, sEventText }).then((Result) => {
        }).catch((error) => {
          console.log(error);
        });

        this.setState({ MessageDiv: <div className="alert alert-success center">{"Player " + CurrentPlayer + " has had B1000 removed from Money In The Bank."}</div> })
      }
    })
  };

  UpdateScenarioModal(Player) {
    this.setState({
      CardScenario: {
        PlayerName: Player.PlayerName,
        Player: Player.PlayerNumber,
        COU: Player.CustomersOweUs,
        MITB: Player.MoneyInTheBank,
        WOS: Player.WeOweSuppliers
      }
    });
  };

  render() {

    //Render game history here
    const GameHistoryData = this.state.GameHistory.map((HistoryObj, iloop) => (
      <table className="tableGH table" border="1">
        <tr>
          <td>{HistoryObj.PlayerName + " - " + HistoryObj.Event}</td>
        </tr>
      </table>
    ));

    const PlayersData = this.state.Players.map((Player, iLoop) => (
      <tr>
        <th>{Player.PlayerNumber}</th>
        <td>{Player.PlayerName}</td>
        <td><i style={{ color: this.state.IconColors[iLoop] }} className={this.state.Icons[iLoop]}></i></td>
        <td>{Player.IsAlive ? "Alive" : "Dead"}{(iLoop + 1) === this.state.GameState.CurrentPlayer ? " - Playing" : ""}</td>        
        <td>{Player.MoneyInTheBank + Player.CustomersOweUs - Player.WeOweSuppliers}</td>
        <td>{<button data-toggle="modal" onClick={() => this.UpdateScenarioModal(Player)} data-toggle="modal" data-target="#exampleModal" className="btn-sm btn-primary LegderButton" type="button">Money Record</button>}</td>
      </tr>
    ));

    const PlayersTableData = this.state.Players.map((Player, iLoop) => (
      <tr className={Player.PlayerName === this.state.PlayerName ? "bg-primary" : ""} key={Player._id}>
        <td>{Player.PlayerNumber}</td>
        <td>{Player.PlayerName}</td>
        <td>
          <div className="Icons1" >
            <i style={{ color: this.state.IconColors[iLoop] }} className={this.state.Icons[iLoop]}></i>
          </div>
        </td>
        <td><button onClick={() => this.UpdateScenarioModal(Player)} data-toggle="modal" data-target="#exampleModal" className="btn btn-outline-dark">View</button></td>
      </tr>
    ));

    const BoardDataObject = this.state.BoardData.map((Obj, iLoop) => (
      <div key={Obj._id} className="GridInfo">
        <img className="boardimg" src={"/img/BigBucks/" + (iLoop + 1) + ".PNG"}></img>
        <div>{this.state.Players.map((Player, iLoop2) => (
          (iLoop + 1) === Player.PlayerPosition ? <td><i style={{ color: this.state.IconColors[iLoop2] }} className={this.state.Icons[iLoop2]}></i></td> : ""
        ))}</div>
      </div>
    ));

    return (
      <div>
        <LoadingBar color="orange" height={10} ref={this.MyLoadingBar} shadow={true} />
        <br></br>
        <div>{this.state.MessageDiv}</div>
        <div hidden={this.state.GameStartStuff} class="gamestartstuff container center padding-top">

          <h1>Office Code: {this.state.GameState.OfficeCode}</h1>
          <table className="table table-hover table-center text-center">
            <thead>
              <tr>
                <th>#</th>
                <th>Business Partner</th>
                <th>Game Avatar</th>
                <th>View Record</th>
              </tr>
            </thead>
            <tbody>
              {PlayersTableData}
            </tbody>
          </table>
          <p>{this.state.PlayersMsg}</p>
          <button disabled={this.GameBtnDisabled} onClick={() => this.startGame()} className="btn btn-dark">{this.state.GameButtonText}</button>
          <div className="padding-top"></div>
        </div>


        <div hidden={this.state.GameStartedStuff} className="center padding-top padding-bottom">
          <div className="center GameContainer GameSpacing">
            <div className="row">
              <div className="col-sm-6 col-10 col-lg-6 col-md-10">
                <h3>Players in Office: {this.state.GameState.OfficeCode}</h3>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Token</th>
                      <th scope="col">Status</th>
                      <th scope="col">NetWorth</th>
                      
                      <th scope="col">View Ledger</th>
                    </tr>
                  </thead>
                  <tbody>
                    {PlayersData}
                  </tbody>
                </table>
              </div>
              <div className="col-sm-6 col-10 col-lg-6 col-md-10">
                <div className="center container">
                  <div className="GameCard">
                    {/* Game history table */}
                    <h3>Game History</h3>
                    <table className="tableGH-scrollbar centerTable tableGH-wrapper-scroll-y">
                      <tbody>
                        {GameHistoryData}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="row center pt-5">
              <div className="col">
                <button className="btn btn-warning" onClick={() => this.skipPlayer()}>Skip Player</button>
                <button className="btn btn-warning" onClick={() => this.killPlayer()}>Remove Current Player</button>
                <button className="btn btn-warning" onClick={() => this.awardMoney()}>Award B1000</button>
                <button className="btn btn-warning" onClick={() => this.punishMoney()}>Deduct B1000</button>
              </div>
            </div>

            <div className="row">
              <div className="col py-3">
                <button className="btn btn-danger center" onClick={() => this.EndGame()}>End The Game</button>
              </div>
            </div>

          </div>

          {/* board data*/}
          <div className="GridContainer padding-top">
            {BoardDataObject}
          </div>
        </div>

        <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{"Viewing Scenario For: " + this.state.CardScenario.PlayerName}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item"><strong>Customers Owe Us: </strong>{"BB -> " + this.state.CardScenario.COU}</li>
                  <li className="list-group-item"><strong>Money In The Bank: </strong>{"BB -> " + this.state.CardScenario.MITB}</li>
                  <li className="list-group-item"><strong>We Owe Suppliers: </strong>{"BB -> " + this.state.CardScenario.WOS}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>


      </div>
    );
  }
}

export default BusinessAdvisorControlPanelComponent;