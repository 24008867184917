import React, { Component } from "react";
import axios from "axios";
import LoadingBar from 'react-top-loading-bar';

class CreateOfficeComponent extends Component {
  constructor() {
    super();
    this.state = {
      frmBusinessAdvisorName: "",
      frmGoogleLink: "",
      frmTurnTime: "",
      frmBusinessName: "",
      CreateLobbyButtonText: "Open Office",
      CreateLobbyDisabled: false,
      ResponseMessage: null,
      ModalResponse: null
    };
    this.MyLoadingBar = React.createRef();
  };

  componentDidMount() {
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.MyLoadingBar.current.continuousStart();
    this.setState({ CreateLobbyButtonText: "Opening Office, Please Stand By!" , CreateLobbyDisabled: true});

    const {frmBusinessAdvisorName, frmGoogleLink, frmTurnTime, frmBusinessName} = this.state;
    axios.post("/api/CreateOffice", {frmBusinessAdvisorName, frmGoogleLink, frmTurnTime, frmBusinessName}).then((Result) => {
      if (Result.data.Success){
        this.MyLoadingBar.current.complete();
        this.props.history.push("/BAControlPanel/" + Result.data.ID + "/" + Result.data.BusinessAdvisorName);
      } else {
        this.MyLoadingBar.current.complete();
        this.setState({ResponseMessage:
          <div className="alert alert-danger">{Result.data.Reason}</div>
        });
      };
    });
  };

  RejoinBA(e){
    e.preventDefault();
    
    const frmBAName = this.state.frmBusinessAdvisorNameRejoin;
    const frmOfficeCode = this.state.frmOfficeCodeRejoin;
    axios.post("/api/AdvisorRejoin", {frmBAName, frmOfficeCode}).then((Result) => {
      if(Result.data.Success){
        this.props.history.push("/BAControlPanel/" + Result.data.ID + "/" + Result.data.Name);
      }else{
        this.setState({ModalResponse: 
          <div className="alert alert-danger">{Result.data.Reason}</div>
        }); 
      };
    });

  };


  render() {

    return (
      <div>
        <LoadingBar color="orange" height={10} ref={this.MyLoadingBar} shadow={true} />
        <form onSubmit={this.onSubmit}>          
          <div className="login-form">          
            <h2 className="text-center">Open Big Bucks Office</h2>
            <div className="form-group text-center">
              <label for="frmBusinessAdvisorName">Business Advisor's Name</label>
              <input placeholder="Enter Name Of The Business Advisor Here" required onChange={this.onChange} id="frmBusinessAdvisorName" type="text" className="form-control text-center"></input>
            </div>
            <div className="form-group text-center">
              <label for="frmBusinessName">Business Name</label>
              <input placeholder="Enter Your Business Name" required onChange={this.onChange} id="frmBusinessName" type="text" className="form-control text-center"/>
            </div>
            <div className="form-group text-center">
              <label for="frmGoogleLink">Google Sheets Ledger Link</label>
              <input placeholder="Enter The Link To The Google Sheets Ledger To Share With The Players" required onChange={this.onChange} id="frmGoogleLink" type="text" className="form-control text-center"></input>
            </div>
            <div className="form-group text-center">
              <label for="frmTurnTime">Player Turn Time <strong>(1-15 Minutes)</strong></label>
              <input min="1" max="15" placeholder="How Long Should Each Players Turn Be?" required onChange={this.onChange} id="frmTurnTime" type="number" className="form-control text-center"></input>
            </div>
            <div className="form-group text-center">
              <label>By Opening A BigBucks Office, You Agree To Our <a target="_blank" href="/terms-and-conditions">Terms & Conditions</a></label>
            </div>
            <div className="form-group">
              <button disabled={this.state.CreateLobbyDisabled} type="submit" className="btnCreateLobby btn-primary btn-block btn-lg">{this.state.CreateLobbyButtonText}</button>
            </div>
            <div className="form-group">
              <button type="button" data-toggle="modal" data-target="#exampleModal" className="btnCreateLobby btn-outline-primary btn-block btn-lg">{"Advisor Rejoin Previous Lobby"}</button>
            </div>
            {this.state.ResponseMessage}
          </div>
        </form>
        <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{"Business Advisor Rejoin Control Panel"}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {this.state.ModalResponse}
                <form onSubmit={(e) => this.RejoinBA(e)}>
                  <div className="form-group text-center">
                    <label for="frmBusinessAdvisorName">Business Advisor's Name</label>
                    <input placeholder="Enter Name Of The Business Advisor Here" required onChange={this.onChange} id="frmBusinessAdvisorNameRejoin" type="text" className="form-control text-center"></input>
                  </div>
                  <div className="form-group text-center">
                    <label for="frmBusinessAdvisorName">Office Code</label>
                    <input placeholder="Enter The Previous Office Code Here" required onChange={this.onChange} id="frmOfficeCodeRejoin" type="text" className="form-control text-center"></input>
                  </div>
                  <div className="form-group">
                    <button type="submit" className="btnCreateLobby btn-primary btn-block btn-lg">Rejoin</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateOfficeComponent;